<template>
  <b-sidebar
    id="add-new-rate-sidebar"
    :visible="isAddNewRoleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="onFormToggle"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{isEdit ? 'Update Role' : 'Add New Role'}}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
              :state="getValidationState(validationContext)"
            >
             <b-form-input
                id="name"
                v-model="roleData.name"
                autofocus
                trim
                :state="getValidationState(validationContext)"
                placeholder="Name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Permission -->
          <validation-provider
            #default="validationContext"
            name="Permission"
          >
          <b-form-group label="Permission" label-for="permission">
               <v-select
                v-model="roleData.permissions"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="permissions.map(permission => ({
                  label: `${permission.action}  ${permission.subject}`,
                  value: permission.id
                }))"
                :reduce="(val) => val.value"
                :clearable="false"
                multiple
                chips
                input-id="is-enabled"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{isEdit ? 'Update' : 'Add'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { onMounted, ref, watch } from "@vue/composition-api";
import { required, alphaNum, email, alpha, password, confirmed } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import useRole from "../useRole";
import usePermission from '@views/apps/permission/usePermission';
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { nigerianStates } from '@core/utils/utils'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewRoleSidebarActive",
    event: "update:is-add-new-role-sidebar-active",
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    roleToUpdate: {
      type: Object,
      required: false,
    }
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      alphaNum,
      alpha,
      email,
      countries,
      password,
      confirmed
    };
  },
  setup(props, { emit }) {
    
    onMounted(() => {
      fetchPermissions()
    })
  
    const toast = useToast();

    let blankRoleData = {
      id: null,
      name: null,
      permissions: null
    };

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)));
    const resetRoleData = () => {
      roleData.value = JSON.parse(JSON.stringify(blankRoleData));
    };

    const onSubmit = () => {
      if (props.isEdit) {
        dispatchUpdateRole();
      } else {
        dispatchAddRole();
      }
    };

    const dispatchAddRole = () => {
      addRole(roleData.value)
        .then((_) => {
          emit("refetch-data");
          emit("update:is-add-new-role-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const dispatchUpdateRole = () => {
      updateRole(roleData.value)
        .then((_) => {
           emit("refetch-data");
           emit("update:is-add-new-role-sidebar-active", false);
        })
        .catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const onFormToggle = (value) => {
        emit('update:is-add-new-role-sidebar-active', value)
        if(value) {
            if(props.isEdit) {
                blankRoleData = {...props.roleToUpdate, permissions: props.roleToUpdate.permissions.map(p => p.id)};
                roleData.value = blankRoleData
            } else {
                props.isEdit = false;
                blankRoleData = {
                  id: null,
                  name: null,
                  permissions: null
                }; 
                roleData.value = blankRoleData
            }
        }
    }

    const handleDateChange = (event) => {
      console.log(event)
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetRoleData);

    const { setRole, addRole, updateRole } = useRole();
    const { permissions, fetchPermissions } = usePermission()

    return {
      roleData,
      onSubmit,
      handleDateChange,
      permissions,
      refFormObserver,
      getValidationState,
      resetForm,
      onFormToggle
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
